<template>
  <div class="apply4">
    <van-nav-bar
      :title="pagetitle"
      :border="false"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-if="shoptype >= 2"
        v-model="companyname"
        name="companyname"
        label="商户名称"
        placeholder="公司名称"
        :rules="[{ required: true, message: '请输入营业执照商户名称' }]"
      />
      <van-field
        v-if="shoptype >= 2"
        v-model="companyno"
        name="companyno"
        label="注册号"
        placeholder="请输入营业执照上的信用代码"
        :rules="[{ required: true, message: '请输入营业执照上的信用代码' }]"
      />
      <van-field
        v-model="name"
        name="name"
        :label="shoptype >= 2 ? '法人姓名' : '姓名'"
        :placeholder="shoptype >= 2 ? '请输入法人姓名' : '请输入身份证上的姓名'"
        :rules="[{ required: true, message: '请输入身份证上的姓名' }]"
      />
      <van-field
        v-model="sfzno"
        name="sfzno"
        label="身份证号"
        placeholder="请输入身份证号码"
        :rules="[{ required: true, message: '请输入身份证号码' }]"
      />
      <van-field
        readonly
        clickable
        v-model="sfznoendtime"
        @click-input="showday = true"
        name="sfznoendtime"
        label="身份证有效期"
        placeholder="身份证有效期"
        :rules="[{ required: true, message: '请选择身份证有效期' }]"
      >
        <template #button>
          <van-checkbox @click="sfzLang" v-model="sfznoendtimeLang" shape="square">长期</van-checkbox>
        </template>
      </van-field>
      <van-field
        v-model="mobile"
        type="tel"
        name="mobile"
        label="手机号"
        placeholder="请输入联系人手机号"
        :rules="[{ required: true, message: '请输入联系人手机号' }]"
      />
      <van-field
        v-model="uemail"
        name="uemail"
        label="邮箱"
        placeholder="请输入接收通知邮箱"
        :rules="[{ required: true, message: '请输入接收通知邮箱' }]"
      />
      <van-field
        readonly
        clickable
        name="bankname"
        :value="bankname"
        label="开户银行"
        placeholder="点击选择开户银行名称"
        @click="showBankPicker = true"
        :rules="[{ required: true, message: '请选择开户银行名称' }]"
      />
      <van-field
        v-model="bankno"
        type="digit"
        name="bankno"
        :label="shoptype == 3 ? '对公账户' : '银行卡号'"
        placeholder="请输入同身份证开户的银行卡号"
        :rules="[{ required: true, message: '请设置您的银行卡号' }]"
      />
      <van-field
        v-model="bankpart"
        name="bankpart"
        label="开户行支行"
        placeholder="请输入开户行支行"
        :rules="[{ required: true, message: '请输入开户行支行' }]"
      />
      <van-field
        readonly
        clickable
        name="area"
        :value="areatext"
        label="开户行地区"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-field
        label="上传证件"
        :border="false"
        readonly
      />
      <van-field :border="false">
        <template #input>
          <div class="tishi">证件文字需能清晰辨认，格式jpg、png、jpeg，小于2M；勿遮挡或模糊，参考示例照片拍摄 <span>查看示例</span></div>
        </template>
      </van-field>
      <van-field :border="false">
        <template #input>
          <div style="width:100%; height: 88px; display:flex;">
            <van-uploader
              style="width:50%; height: 80px;"
              v-model="uploader1"
              :max-count="1"
              upload-text="身份证头像面"
              :after-read="afterRead1"
              :before-read="beforeRead"
              @oversize="oversize"
              :max-size="2 * 1024 * 1024"
            />
            <van-uploader
              style="width:50%; height: 80px;"
              v-model="uploader2"
              :max-count="1"
              upload-text="身份证国徽面"
              :after-read="afterRead2"
              :before-read="beforeRead"
              @oversize="oversize"
              :max-size="2 * 1024 * 1024"
            />
          </div>
        </template>
      </van-field>
      <van-field :border="false">
        <template #input>
          <div style="width:100%; height: 88px; display:flex;" v-if="shoptype >= 2">
            <van-uploader
              style="width:50%; height: 80px;"
              v-model="uploader3"
              :max-count="1"
              :upload-text="shoptype == 2 ? '个体营业执照' : '公司营业执照'"
              :after-read="afterRead3"
              :before-read="beforeRead"
              @oversize="oversize"
              :max-size="2 * 1024 * 1024"
            />
            <van-uploader
              style="width:50%; height: 80px;"
              v-model="uploader4"
              :max-count="1"
              upload-text="其他资质(非必选)"
              :after-read="afterRead4"
              :before-read="beforeRead"
              @oversize="oversize"
              :max-size="2 * 1024 * 1024"
            />
          </div> 
        </template>
      </van-field>

      <div style="margin: 16px;">
        <van-button block type="danger" native-type="submit">
          下一步
        </van-button>
      </div>
    </van-form>
    <!-- 开户银行选择弹框 -->
    <van-popup v-model="showBankPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="bankList"
        @confirm="onConfirm"
        @cancel="showBankPicker = false"
      />
    </van-popup>
    <!-- 开户行地区 -->
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        :value="areacode"
        @confirm="onConfirmArea"
        @cancel="showArea = false"
      />
    </van-popup>
    <!-- 身份证有效期 -->
    <van-popup v-model="showday" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirmdate"
        @cancel="showday = false"
      />
    </van-popup>
  </div>
</template>
<script>
import areaList from "@/components/provinceCity.js";
export default {
  name: "GonghuoApply4",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      regid: 0,
      shoptype: 0, // 企业类型 1 小微企业，2个体工商户，3公司
      pagetitle: "",
      name: "",
      sfzno: "",
      mobile: "",
      uemail: "",
      bankname: "",
      bankno: "",
      bankpart: "",
      companyname: "",
      companyno: "",
      bankList: ['工商银行','交通银行','招商银行','民生银行','中信银行','浦发银行','兴业银行','光大银行','广发银行','平安银行','北京银行','华夏银行','农业银行','建设银行','邮政储蓄银行','中国银行','宁波银行','其他银行'],
      uploader1:[], // 上传列表
      uploader2:[], // 上传列表
      uploader3:[], // 上传列表
      uploader4:[], // 上传列表
      sfzz: "",
      sfzf: "",
      yyzz: "",
      qtzz: "",
      shareid: 0,
      merchid: 0,
      showBankPicker: false,
      company: {},
      showArea: false,
      areaList: areaList, // 地区列表
      areacode: "", // 地址code
      areatext: "", // 地址名称
      showday: false, // 日期选择
      currentDate: new Date(new Date().getFullYear(), new Date().getMonth()+1, 1), // 日期
      sfznoendtime: "", // 日期
      minDate: new Date(new Date().getFullYear(), 0, 1), 
      maxDate: new Date(new Date().getFullYear()+30, 0, 1), 
      sfznoendtimeLang: 0, // 身份证是否长期
    }
  },
  mounted() {
    this.shoptype = this.$route.query.shoptype;
    this.regid = this.$route.query.regid;
    if (this.shoptype == 1) {
      this.pagetitle = "小微商户认证";
    }else if(this.shoptype == 2) {
      this.pagetitle = "个体商户认证";
    }else if(this.shoptype == 3) {
      this.pagetitle = "公司/企业认证";
    }

    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, this.pagetitle);
    this.init();
  },
  methods: {
    init() {
      this.getinfo();

      // _that.shoptype = _that.company.shoptype // 企业类型 1 小微企业，2个体工商户，3公司
      if (this.shoptype == 1) {
        this.pagetitle = "小微商户认证";
      }else if(this.shoptype == 2) {
        this.pagetitle = "个体商户认证";
      }else if(this.shoptype == 3) {
        this.pagetitle = "公司/企业认证";
      }
    },
    getinfo() {
      let _that = null;
      _that = this;
      let gonghuo1 = null;
      gonghuo1 = localStorage.getItem("gonghuo1");
      gonghuo1 = this.$qs.parse(gonghuo1);
      console.log(gonghuo1);
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/get_companyInfo",
          _that.$qs.stringify({
            regid: _that.regid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.company = res.data.data;
            _that.name = _that.company.rz_name
            _that.sfzno = _that.company.idcardno
            _that.mobile = _that.company.rz_mobile
            _that.uemail = _that.company.bindemail
            _that.bankname = _that.company.bank_name
            _that.bankno = _that.company.bank_no
            _that.bankpart = _that.company.bank_part
            _that.companyname = _that.company.companyname
            _that.sfznoendtime = _that.company.idcardtime
            _that.areacode = _that.company.bank_area_code
            _that.areatext = _that.company.bank_area
            
            let timedata = [];
            timedata = _that.company.idcardtime.split("-");
            _that.currentDate =  new Date(timedata[0], timedata[1]-1, timedata[2])
            
            if (!_that.name && gonghuo1.name) {
              _that.name = gonghuo1.name;
            }
            if (!_that.sfzno && gonghuo1.sfzno) {
              _that.sfzno = gonghuo1.sfzno;
            }
            if (!_that.mobile && gonghuo1.mobile) {
              _that.mobile = gonghuo1.mobile;
            }
            if (!_that.uemail && gonghuo1.uemail) {
              _that.uemail = gonghuo1.uemail;
            }
            if (!_that.bankname && gonghuo1.bankname) {
              _that.bankname = gonghuo1.bankname;
            }
            if (!_that.bankno && gonghuo1.bankno) {
              _that.bankno = gonghuo1.bankno;
            }
            if (!_that.bankpart && gonghuo1.bankpart) {
              _that.bankpart = gonghuo1.bankpart;
            }
            if (!_that.companyname && gonghuo1.companyname) {
              _that.companyname = gonghuo1.companyname;
            }

            if (!_that.companyno && gonghuo1.companyno) {
              _that.companyno = gonghuo1.companyno;
            }

            if (_that.company.idcard_z) {
              _that.uploader1 = [{url: _that.company.idcard_z}] // 上传列表
              _that.sfzz = _that.company.idcard_z
            }else if(gonghuo1.sfzz) {
              _that.uploader1 = [{url: gonghuo1.sfzz}] // 上传列表
              _that.sfzz = gonghuo1.sfzz
            }

            if (_that.company.idcard_f) {
              _that.uploader2 = [{url: _that.company.idcard_f}] // 上传列表
              _that.sfzf = _that.company.idcard_f
            }else if(gonghuo1.sfzf) {
              _that.uploader2 = [{url: gonghuo1.sfzf}] // 上传列表
              _that.sfzf = gonghuo1.sfzf
            }

            if (_that.company.zhizhao) {
              _that.uploader3 = [{url: _that.company.zhizhao}] // 上传列表
              _that.yyzz = _that.company.zhizhao
            }else if(gonghuo1.yyzz) {
              _that.uploader3 = [{url: gonghuo1.yyzz}] // 上传列表
              _that.yyzz = gonghuo1.yyzz
            }

            if (_that.company.zhizhao_other) {
              _that.uploader4 = [{url: _that.company.zhizhao_other}] // 上传列表
              _that.qtzz = _that.company.zhizhao_other
            }else if(gonghuo1.qtzz) {
              _that.uploader4 = [{url: gonghuo1.qtzz}] // 上传列表
              _that.qtzz = gonghuo1.qtzz
            }
            
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    onConfirm(val) {
      console.log(val);
      this.bankname = val;
      this.showBankPicker = false;
    },
    // 读取文件完成之前
    beforeRead(file){
      console.log(file);
      if (file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
        return true;
      }else{
        this.$toast({
          message: "请上传jpg、png、jpeg格式的图片"
        })
        return false;
      }
    },
    // 超出大小限制
    oversize(file){
      console.log(file, "oversize");
      this.$toast('文件大小不能超过 2M');
    },
    // 读取文件完成之后
    afterRead1(file) {
      console.log(file);
      let _that = null;
      _that = this;
      
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/apply/');

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      _that.$axios.post(
        _that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        
        if (response.data && response.data.code == 100000) {
          _that.sfzz = response.data.img_url;
          _that.$toast(response.data.msg ? response.data.msg : "图片上传成功");
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "图片上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    afterRead2(file) {
      console.log(file);
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/apply/');

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      _that.$axios.post(
        _that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data && response.data.code == 100000) {
          _that.sfzf = response.data.img_url
          _that.$toast(response.data.msg ? response.data.msg : "图片上传成功");
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "图片上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    afterRead3(file) {
      console.log(file);
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/apply/');

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      _that.$axios.post(
        _that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data && response.data.code == 100000) {
          _that.yyzz = response.data.img_url
          _that.$toast(response.data.msg ? response.data.msg : "图片上传成功");
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "图片上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    afterRead4(file) {
      console.log(file);
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/apply/');

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      _that.$axios.post(
        _that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data && response.data.code == 100000) {
          _that.qtzz = response.data.img_url
          _that.$toast(response.data.msg ? response.data.msg : "图片上传成功");
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "图片上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },

    // 长期选择
    sfzLang(val) {
      console.log(val, this.sfznoendtimeLang);
      if (this.sfznoendtimeLang) {
        this.sfznoendtime = "长期";
      }
    },
    // 日期选择
    onConfirmdate(values) {
      console.log(values);
      let year = "";
      let month = "";
      let day = "";
      year = new Date(values).getFullYear();
      month = new Date(values).getMonth()+1;
      day = new Date(values).getDate();
      year = "" + year;
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.sfznoendtime = year + "-" + month + "-" + day;
      console.log(year,month,day);
      this.sfznoendtimeLang = 0;
      this.showday = false;
    },
    // 地区选择
    onConfirmArea(values) {
      this.areatext = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      console.log(values);
      this.areacode = values[values.length-1].code;
      console.log(this.areacode);
      this.showArea = false;
    },

    onSubmit() {
      let _that = null;
      _that = this;
      let gonghuo1 = null;
      gonghuo1 = localStorage.getItem("gonghuo1");
      gonghuo1 = _that.$qs.parse(gonghuo1);

      gonghuo1.name = _that.name;
      gonghuo1.companyname = _that.companyname;
      gonghuo1.companyno = _that.companyno;
      gonghuo1.sfzno = _that.sfzno;
      gonghuo1.mobile = _that.mobile;
      gonghuo1.uemail = _that.uemail;
      gonghuo1.bankname = _that.bankname;
      gonghuo1.bankno = _that.bankno;
      gonghuo1.bankpart = _that.bankpart;
      gonghuo1.sfzz = _that.sfzz;
      gonghuo1.sfzf = _that.sfzf;
      gonghuo1.yyzz = _that.yyzz;
      gonghuo1.qtzz = _that.qtzz;
      gonghuo1.areacode = _that.areacode;
      gonghuo1.areatext = _that.areatext;
      gonghuo1.sfznoendtime = _that.sfznoendtime;
      console.log(gonghuo1);
      
      localStorage.setItem("gonghuo1",_that.$qs.stringify(gonghuo1));

      _that.$router.push({
        name: "GonghuoApply5",
        query: {
          regid: _that.regid,
          shareid: _that.shareid,
          merchid: _that.merchid,
          t: new Date().getTime()
        }
      });

      /*
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/compay_info",
          _that.$qs.stringify({
            regid: _that.regid,
            shoptype: _that.shoptype, // 企业类型 1 小微企业，2个体工商户，3公司
            name: _that.name,
            sfzno: _that.sfzno,
            mobile: _that.mobile,
            uemail: _that.uemail,
            bankname: _that.bankname,
            bankno: _that.bankno,
            bankpart: _that.bankpart,
            companyname: _that.companyname,
            sfzz: _that.sfzz,
            sfzf: _that.sfzf,
            yyzz: _that.yyzz,
            qtzz: _that.qtzz,
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$router.push({ name: "GonghuoApply5", queyr: { regid: _that.regid, shareid: _that.shareid, merchid: _that.merchid } });
          }else {
            _that.$notify({
              type: "warning",
              message: res.data.msg ? res.data.msg : "保存失败"
            })
          }
        })
        .catch(error => {
          console.log(error);
        })
      */
    }
  }
}
</script>
<style lang="less">
.apply4 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-cell {
    line-height: 30px;
    .van-cell__value {
      flex: 1;
      .tishi {
        font-size: 12px;
        color: #999;
        line-height: 20px;
      }
      .tishi2 {
        font-size: 12px;
        color: #F3A793;
        line-height: 20px;
        text-align: center;
        width: 100%;
      }
      .van-uploader__upload,
      .van-uploader__preview-image,
      .van-uploader__preview{
        width: 100%;
      }
      .van-cell {
        padding: 0;
        .sfznoendtime{
          width: 100%;
          padding: 0;
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>